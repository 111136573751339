<template>
    <div>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="8" class="component-title" style="cursor: pointer;" v-if="!isMobile()">
                Course Search
            </el-col>
            <el-col :span="8" v-else>
                &nbsp;
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row style="background-color: #7d90d0; padding: 1%;">
            <el-col :span="8" style="padding: 0 1vw;">
                <el-select
                        @change="changeTest"
                        placeholder="Select by Test type"
                        type="text"
                        v-model="testType"
                        class="el-input form-search-bar">
                    <el-option
                            :label="'Career'"
                            :value="'career'">
                    </el-option>
                    <el-option
                            :label="'Personality'"
                            :value="'personality'">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row v-if="getCareerTestQuestions.length > 0 && testType === 'career'"
                style="margin-top: 2vh; height: 80vh; overflow-y: auto; width: 100%;">
            <data-tables :data="getCareerTestQuestions" :page-size="5" :filters="filters"
                         style="width: 100%;"
                         :pagination-props="{ pageSizes: [5, 10] }">
                <el-row slot="tool" style="margin: 10px 0">
                    <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                        <el-input placeholder="Search question" v-model="filters[0].value"></el-input>
                    </el-col>
                </el-row>
                <el-table-column label="Question" :prop="'Question'" width="700"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.question"/>
                    </template>
                </el-table-column>
                <el-table-column label="Sectors" :prop="'sectors'" width="350"
                                 :sortable="true"></el-table-column>
                <el-table-column label="Sub Sectors" :prop="'sub_sectors'" width="350"
                                 :sortable="true"></el-table-column>
                <el-table-column
                        fixed="right"
                        label="Operations"
                        width="120">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="editRow(scope.$index, scope.row)">
                            Save
                        </el-button>
                    </template>
                </el-table-column>
            </data-tables>
        </el-row>
        <el-row v-if="getPersonalityTestQuestions.length > 0 && testType === 'personality'"
                style="margin-top: 2vh; height: 80vh; overflow-y: auto;">
            <data-tables :filters="filters" :data="getPersonalityTestQuestions" :page-size="5"
                         style="height: 80vh; overflow-y: auto;  width: 100%;"
                         :pagination-props="{ pageSizes: [5, 10] }">
                <el-row slot="tool" style="margin: 10px 0">
                    <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                        <el-input placeholder="Search question" v-model="filters[0].value"></el-input>
                    </el-col>
                </el-row>
                <el-table-column label="Question 1" :prop="'question'" width="500"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.question"/>
                    </template>
                </el-table-column>
                <el-table-column label="Question 1 Personality" :prop="'personality'" width="250"
                                 :sortable="true"></el-table-column>
                <el-table-column label="Question 2" :prop="'related_question'" width="500"
                                 :sortable="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.related_question"/>
                    </template>
                </el-table-column>
                <el-table-column label="Question 2 Personality" :prop="'related_question_personality'" width="250"
                                 :sortable="true"></el-table-column>
                <el-table-column
                        fixed="right"
                        label="Operations"
                        width="120">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="editRow(scope.$index, scope.row)">
                            Save
                        </el-button>
                    </template>
                </el-table-column>
            </data-tables>
        </el-row>
    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'TestQuestions',
    components: {ProfileAvatar},
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        if (response.status === 0 || !self.$store.state.isAdmin) {
          self.$router.push('/');
        } else {
          self.getTestQuestions('career');
        }
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    computed: {
      getCareerTestQuestions: function() {
        return this.careerTestQuestions;
      },
      getPersonalityTestQuestions: function() {
        return this.personalityTestQuestions;
      },
    },
    methods: {
      changeTest: function() {
        this.careerTestQuestions = [];
        this.personalityTestQuestions = [];
        this.getTestQuestions();
      },
      editRow: function(index, row) {
        let self = this;
        let url = '';
        if (self.testType === 'career') {
          url = `${self.getBaseUrl()}/recommendations/careers_questions/update/${row.id}/`;
        } else {
          url = `${self.getBaseUrl()}/recommendations/personality_questions/update/${row.id}/`;
        }
        $.ajax({
          url: url,
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify(row),
          beforeSend: function(request) {
            if (self.getAuthToken()) {
              request.setRequestHeader(
                  'Authorization',
                  'Token ' + self.getAuthToken(),
              );
            }
          },
          success: function(response) {
            self.$message({
              title: 'Successful',
              message: 'Course data successfully updated',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
          },
          error: function(error) {
            console.log(error);
            self.$message({
              title: 'Unsuccessful',
              message: 'Update failed',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
          },
        });
      },
      getTestQuestions: function(test = 'career') {
        let self = this;
        let url = '';
        if (self.testType === 'career') {
          url = self.getBaseUrl() + '/recommendations/careers_questions/list/';
        } else {
          url = self.getBaseUrl() + '/recommendations/personality_questions/list/';
        }
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          success: function(response) {
            if (self.testType === 'career') {
              self.careerTestQuestions = response;
            } else {
              self.personalityTestQuestions = response;
            }
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            console.log(error);
            self.$store.state.loadingScreen = false;
          },
        });
      },
    },
    data: function() {
      return {
        testType: 'career',
        loadingScreen: false,
        careerTestQuestions: [],
        personalityTestQuestions: [],
        filters: [
          {
            value: '',
            prop: 'question',
          }, {
            value: [],
          }],
      };
    },
  };
</script>

<style scoped>

</style>
